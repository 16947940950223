























































































import { mapActions, mapState } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import { LoginForm } from '@/store/modules/user'

export default defineComponent({
  name: 'PagesLoginIndex',
  components: {
    PagesBtn: () => import('./components/Btn.vue'),
  },
  data: () => ({
    loading: false,
    email: '',
    password: '',
    showPassword: false,
  }),

  computed: {
    ...mapState('user', [
      'message',
      'routeAfterLogin',
    ]),
  },
  methods: {
    ...mapActions('user', {
      login: 'login',
    }),
    async onSubmit () {
      const loginForm = { email: this.email, password: this.password } as LoginForm
      this.loading = true
      try {
        await this.login(loginForm)
        await this.$router.push(this.routeAfterLogin)
      } finally {
        this.loading = false
      }
    },
  },
})
